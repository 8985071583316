import React from 'react';
import styled from 'styled-components';
import { FiPlus } from 'react-icons/fi';

const Form = styled.form`
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: ${props => props.theme.slideIn} 0.5s ease-out;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
  }
`;

const Button = styled.button`
  background: ${props => props.theme.buttonGradient};
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CreateURLForm = ({ newUrl, handleInputChange, handleCreateUrl }) => {
    return (
        <Form onSubmit={handleCreateUrl}>
            <Input
                type="url"
                name="androidUrl"
                value={newUrl.androidUrl}
                onChange={handleInputChange}
                placeholder="Android URL"
                required
            />
            <Input
                type="url"
                name="iosUrl"
                value={newUrl.iosUrl}
                onChange={handleInputChange}
                placeholder="iOS URL"
                required
            />
            <Input
                type="url"
                name="webUrl"
                value={newUrl.webUrl}
                onChange={handleInputChange}
                placeholder="Web URL"
                required
            />
            <Button type="submit" onClick={handleCreateUrl}>
                <FiPlus /> Create URL
            </Button>
        </Form>
    );
};

export default CreateURLForm;
// Modal.js
import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${props => props.theme.fadeIn} 0.3s ease-out;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 400px;
  width: 100%;
  animation: ${props => props.theme.slideIn} 0.3s ease-out;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const Button = styled.button`
  background: ${props => props.theme.buttonGradient};
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const Modal = ({ onCancel, onConfirm }) => (
    <ModalWrapper>
        <ModalContent>
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete this URL? This action cannot be undone.</p>
            <ModalButtons>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onConfirm} style={{ background: 'linear-gradient(45deg, #ff416c, #ff4b2b)' }}>
                    Delete
                </Button>
            </ModalButtons>
        </ModalContent>
    </ModalWrapper>
);

export default Modal;
import React from 'react';
import styled from 'styled-components';
import { FiLink, FiPieChart, FiDollarSign, FiLogOut } from 'react-icons/fi';

const Sidebar = styled.nav`
  width: 240px;
  background: ${props => props.theme.sidebarGradient};
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  animation: ${props => props.theme.fadeIn} 0.5s ease-out;

  @media (max-width: 768px) {
    position: fixed;
    left: ${props => props.isOpen ? '0' : '-240px'};
    top: 0;
    bottom: 0;
    z-index: 1000;
  }
`;

const Logo = styled.h1`
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const NavItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  margin-bottom: 0.5rem;
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.875rem;

  &:hover, &.active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    transform: translateX(5px);
  }

  svg {
    margin-right: 1rem;
    font-size: 1.2rem;
  }
`;

const Navigation = ({ isOpen, activeTab, setActiveTab, handleLogout }) => {
    return (
        <Sidebar isOpen={isOpen}>
            <Logo>QuickLink</Logo>
            <NavItems>
                <NavItem>
                    <NavLink className={activeTab === 'urls' ? 'active' : ''} onClick={() => setActiveTab('urls')}>
                        <FiLink /> URLs
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={activeTab === 'analytics' ? 'active' : ''} onClick={() => setActiveTab('analytics')}>
                        <FiPieChart /> Analytics
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={activeTab === 'billing' ? 'active' : ''} onClick={() => setActiveTab('billing')}>
                        <FiDollarSign /> Billing
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={handleLogout}>
                        <FiLogOut /> Logout
                    </NavLink>
                </NavItem>
            </NavItems>
        </Sidebar>
    );
};

export default Navigation;
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// RedirectComponent for handling short URLs
const RedirectComponent = () => {
  const { shortURL } = useParams();

  useEffect(() => {
    const getRedirectUrl = async () => {
      try {
        // Make an API call to get URLs based on the shortURL key
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/r/${shortURL}`);
        const { webUrl, androidUrl, iosUrl } = response.data;

        // Platform detection using navigator.userAgent
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        let redirectUrl = webUrl; // Default to web URL
        if (/android/i.test(userAgent)) {
          redirectUrl = androidUrl;
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
          redirectUrl = iosUrl;
        }

        // Perform the redirection
        window.location.href = redirectUrl;
      } catch (error) {
        console.error('Error retrieving URLs:', error);
        // Handle error (e.g., redirect to a default page or show an error message)
        window.location.href = '/error';
      }
    };

    getRedirectUrl();
  }, []);

  return <div>Redirecting...</div>;
};

export default RedirectComponent;

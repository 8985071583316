// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FiMenu, FiX } from 'react-icons/fi';
import styled, { createGlobalStyle, ThemeProvider, keyframes } from 'styled-components';
import Navigation from './Navigation';
import CreateURLForm from './CreateURLForm';
import Modal from './Modal';
import Analytics from './Analytics';
import Billing from './Billing';
import URLCard from './URLCard';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  
  body {
    font-family: 'Poppins', sans-serif;
    background-color: #f0f4f8;
    color: #2d3748;
    margin: 0;
    padding: 0;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const DashboardContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Content = styled.main`
  flex-grow: 1;
  padding: 2rem;
  background-color: #f0f4f8;
  overflow-y: auto;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  animation: ${slideIn} 0.5s ease-out;
`;

const PageTitle = styled.h2`
  font-size: 2rem;
  color: #1a202c;
  font-weight: 700;
  margin: 0;
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: #4a5568;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const URLGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const theme = {
    sidebarGradient: 'linear-gradient(135deg, #6366f1, #a855f7)',
    buttonGradient: 'linear-gradient(135deg, #3b82f6, #8b5cf6)',
    cardGradient: 'linear-gradient(135deg, #f0f9ff, #e0f2fe)',
    fadeIn,
    slideIn,
};

function Dashboard() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('urls');
    const [urls, setUrls] = useState([]);
    const [newUrl, setNewUrl] = useState({ androidUrl: '', iosUrl: '', webUrl: '' });
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
        fetchUrls();
    }, []);

    const fetchUser = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {
              withCredentials: true,
            });
        } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/login');
        }
    };

    const fetchUrls = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/urls`,
              { withCredentials: true }
            );
            setUrls(response.data);
        } catch (error) {
            console.error('Error fetching URLs:', error);
        }
    };

    const handleCreateUrl = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/api/urls`,
              newUrl,
              { withCredentials: true }
            );
            setNewUrl({ androidUrl: '', iosUrl: '', webUrl: '' });
            fetchUrls();
        } catch (error) {
            console.error('Error creating URL:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewUrl({ ...newUrl, [e.target.name]: e.target.value });
    };

    const handleDeleteUrl = (id) => {
        setDeleteConfirmation(id);
    };

    const confirmDeleteUrl = async () => {
        try {
            await axios.delete(
              `${process.env.REACT_APP_API_BASE_URL}/api/urls/${deleteConfirmation}`,
              { withCredentials: true }
            );
            fetchUrls();
            setDeleteConfirmation(null);
        } catch (error) {
            console.error('Error deleting URL:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
              withCredentials: true,
            });
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleCopyUrl = (shortUrl) => {
        navigator.clipboard.writeText(shortUrl);
        // Optionally, add a notification here to inform the user that the URL has been copied
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <DashboardContainer>
                <Navigation
                    isOpen={menuOpen}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleLogout={handleLogout}
                />

                <Content>
                    <Header>
                        <PageTitle>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</PageTitle>
                        <MenuToggle onClick={() => setMenuOpen(!menuOpen)}>
                            {menuOpen ? <FiX /> : <FiMenu />}
                        </MenuToggle>
                    </Header>

                    {activeTab === 'urls' && (
                        <>
                            <CreateURLForm
                                newUrl={newUrl}
                                handleInputChange={handleInputChange}
                                handleCreateUrl={handleCreateUrl}
                            />

                            <URLGrid>
                                {urls.map((url) => (
                                    <URLCard
                                        key={url.id}
                                        url={url}
                                        onCopy={handleCopyUrl}
                                        onDelete={handleDeleteUrl}
                                    />
                                ))}
                            </URLGrid>
                        </>
                    )}
                    {activeTab === 'analytics' && <Analytics />}
                    {activeTab === 'billing' && <Billing />}
                </Content>
            </DashboardContainer>

            {deleteConfirmation && (
                <Modal
                    onCancel={() => setDeleteConfirmation(null)}
                    onConfirm={confirmDeleteUrl}
                />
            )}
        </ThemeProvider>
    );
}

export default Dashboard;
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Dashboard2 from './components/Dashboard2';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RedirectComponent from './components/RedirectComponent';

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      // Skip authentication check for /r/:shortURL routes
      if (location.pathname.startsWith('/r/')) {
        setIsLoading(false);
        return;
      }
      try {
        // Make sure you include 'withCredentials' to allow cookies to be sent
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/user`, { withCredentials: true });
        setUser(response.data.user);
      } catch (error) {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [location.pathname]);

  // While checking auth status, show a loading spinner or nothing
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Routes>
        {/* If user is logged in, redirect from login page to dashboard */}
        <Route path="/login" element={user ? <Navigate to="/dashboard" replace /> : <Login />} />

        {/* Dashboard routes should only be accessible if the user is logged in */}
        <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" replace />} />
        <Route path="/dashboard2" element={user ? <Dashboard2 /> : <Navigate to="/login" replace />} />

        {/* Redirect to dashboard if logged in, otherwise redirect to login */}
        <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />

        {/* Add the short URL redirect route */}
        <Route path="/r/:shortURL" element={<RedirectComponent />} />

        {/* Catch-all route: redirect to login or dashboard depending on user's authentication status */}
        <Route path="*" element={user ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

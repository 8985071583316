import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiCopy, FiTrash2, FiExternalLink, FiRefreshCw } from 'react-icons/fi';

const Card = styled.div`
  background: ${props => props.isActive ? 'linear-gradient(135deg, #ffffff, #f0f4f8)' : 'linear-gradient(135deg, #f5f5f5, #e0e0e0)'};
  border-radius: 20px;
  padding: 1.5rem;
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  opacity: ${props => props.isActive ? 1 : 0.7};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px -5px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ShortURL = styled.h3`
  font-size: 1.5rem;
  color: ${props => props.isActive ? '#4f46e5' : '#9ca3af'};
  margin: 0;
  font-weight: 700;
  text-decoration: ${props => props.isActive ? 'none' : 'line-through'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || '#4f46e5'};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  padding: 0.2rem;

  &:hover {
    transform: scale(1.1);
  }
`;

const URLDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const URLDetailItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: ${props => props.isActive ? '#4b5563' : '#9ca3af'};
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(5px);
  }
`;

const URLLabel = styled.span`
  font-weight: 600;
  margin-right: 0.5rem;
  flex-shrink: 0;
`;

const URLLink = styled.a`
  color: ${props => props.isActive ? '#3b82f6' : '#9ca3af'};
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${props => props.isActive ? 'auto' : 'none'};

  &:hover {
    color: ${props => props.isActive ? '#2563eb' : '#9ca3af'};
    text-decoration: ${props => props.isActive ? 'underline' : 'none'};
  }

  svg {
    margin-left: 0.25rem;
    flex-shrink: 0;
  }
`;

const NotificationBanner = styled.div`
  background: #4f46e5;
  color: white;
  padding: 0.5rem;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease;

  &.show {
    transform: translateY(0);
  }
`;

const DeactivatedBadge = styled.span`
  background-color: #ef4444;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
`;

const URLCard = ({ url, onCopy, onDelete }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [urlWidths, setUrlWidths] = useState({ android: 0, ios: 0, web: 0 });
  const cardRef = useRef(null);

  const handleCopy = () => {
    onCopy(url.short_url);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  useEffect(() => {
    const updateUrlWidths = () => {
      if (cardRef.current) {
        const cardWidth = cardRef.current.offsetWidth;
        setUrlWidths({
          android: cardWidth - 120,
          ios: cardWidth - 120,
          web: cardWidth - 120,
        });
      }
    };

    updateUrlWidths();
    window.addEventListener('resize', updateUrlWidths);
    return () => window.removeEventListener('resize', updateUrlWidths);
  }, []);

  const truncateUrl = (url, maxWidth) => {
    return url.length > maxWidth ? url.substring(0, maxWidth - 3) + '...' : url;
  };

  return (
    <Card ref={cardRef} isActive={url.is_active}>
      <NotificationBanner className={showNotification ? 'show' : ''}>
        URL copied to clipboard!
      </NotificationBanner>
      <CardHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ShortURL isActive={url.is_active}>{url.short_url}</ShortURL>
          {!url.is_active && <DeactivatedBadge>Deactivated</DeactivatedBadge>}
        </div>
        <ActionButtons>
          <IconButton onClick={handleCopy} title="Copy URL">
            <FiCopy />
          </IconButton>
          {url.is_active ? (
            <IconButton onClick={() => onDelete(url.id)} title="Deactivate URL" color="#ef4444">
              <FiTrash2 />
            </IconButton>
          ) : (<></>

          )}
        </ActionButtons>
      </CardHeader>
      <URLDetails>
        <URLDetailItem isActive={url.is_active}>
          <URLLabel>Android:</URLLabel>
          <URLLink href={url.android_url} target="_blank" rel="noopener noreferrer" title={url.android_url} isActive={url.is_active}>
            {truncateUrl(url.android_url, urlWidths.android)}
            <FiExternalLink />
          </URLLink>
        </URLDetailItem>
        <URLDetailItem isActive={url.is_active}>
          <URLLabel>iOS:</URLLabel>
          <URLLink href={url.ios_url} target="_blank" rel="noopener noreferrer" title={url.ios_url} isActive={url.is_active}>
            {truncateUrl(url.ios_url, urlWidths.ios)}
            <FiExternalLink />
          </URLLink>
        </URLDetailItem>
        <URLDetailItem isActive={url.is_active}>
          <URLLabel>Web:</URLLabel>
          <URLLink href={url.web_url} target="_blank" rel="noopener noreferrer" title={url.web_url} isActive={url.is_active}>
            {truncateUrl(url.web_url, urlWidths.web)}
            <FiExternalLink />
          </URLLink>
        </URLDetailItem>
      </URLDetails>
    </Card>
  );
};

export default URLCard;
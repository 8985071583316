import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FiActivity, FiCalendar, FiClock, FiChevronLeft, FiChevronRight, FiSearch } from 'react-icons/fi';
import URLCard from './Analytics/URLAnalyticsCard';

const AnalyticsContainer = styled.div`
  padding: 2rem;
  background-color: #f8fafc;
`;

const HeaderSection = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 1.1rem;
  color: #4a5568;
`;

const OverviewCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const Card = styled.div`
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
  border-radius: 12px;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

const CardTitle = styled.h3`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const CardValue = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
`;

const ChartSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const ChartTitle = styled.h2`
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SearchInput = styled.input`
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  width: 300px;
`;

const PageSizeSelector = styled.select`
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
`;

const URLList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const URLItem = styled.li`
  background: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const URLTitle = styled.h4`
  font-size: 1.1rem;
  color: #2d3748;
  margin: 0 0 0.5rem 0;
`;

const URLStats = styled.p`
  font-size: 0.9rem;
  color: #4a5568;
  margin: 0;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const PageButton = styled.button`
  background: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background 0.3s ease;

  &:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #3182ce;
  }
`;
const URLGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(num);
};

const Analytics = () => {
    const [urlsWithStats, setUrlsWithStats] = useState([]);
    const [totalHits, setTotalHits] = useState(0);
    const [todayHits, setTodayHits] = useState(0);
    const [weeklyHits, setWeeklyHits] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        fetchUrlsWithStats(currentPage, searchTerm, pageSize);
    }, [currentPage, searchTerm, pageSize]);

    const fetchUrlsWithStats = async (page, search, limit) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/urlswithstats`, {
                params: { page, limit, search },
                withCredentials: true
            });
            setUrlsWithStats(response.data.urls);
            setTotalPages(response.data.pagination.totalPages);

            const totals = response.data.urls.reduce((acc, url) => ({
                total: acc.total + parseInt(url.total_hits),
                today: acc.today + parseInt(url.today_hits),
                weekly: acc.weekly + parseInt(url.weekly_hits)
            }), { total: 0, today: 0, weekly: 0 });

            setTotalHits(totals.total);
            setTodayHits(totals.today);
            setWeeklyHits(totals.weekly);
        } catch (error) {
            console.error('Error fetching URLs with stats:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const chartData = urlsWithStats.map(url => ({
        name: url.short_url,
        total: parseInt(url.total_hits),
        today: parseInt(url.today_hits),
        weekly: parseInt(url.weekly_hits)
    }));

    return (
        <AnalyticsContainer>
            <HeaderSection>
                <Title>Analytics Dashboard</Title>
                <Subtitle>Track and analyze your URL performance</Subtitle>
            </HeaderSection>

            <OverviewCards>
                <Card>
                    <CardTitle><FiActivity style={{ marginRight: '0.5rem' }} /> Total Hits</CardTitle>
                    <CardValue>{formatNumber(totalHits)}</CardValue>
                </Card>
                <Card>
                    <CardTitle><FiCalendar style={{ marginRight: '0.5rem' }} /> Today's Hits</CardTitle>
                    <CardValue>{formatNumber(todayHits)}</CardValue>
                </Card>
                <Card>
                    <CardTitle><FiClock style={{ marginRight: '0.5rem' }} /> Weekly Hits</CardTitle>
                    <CardValue>{formatNumber(weeklyHits)}</CardValue>
                </Card>
            </OverviewCards>

            <ChartSection>
                <ChartTitle>URL Performance Overview</ChartTitle>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" fill="#6366f1" name="Total Hits" />
                        <Bar dataKey="weekly" fill="#a855f7" name="Weekly Hits" />
                        <Bar dataKey="today" fill="#ec4899" name="Today's Hits" />
                    </BarChart>
                </ResponsiveContainer>
            </ChartSection>
            <ControlsContainer>
                <SearchInput
                    type="text"
                    placeholder="Search URLs..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <PageSizeSelector value={pageSize} onChange={handlePageSizeChange}>
                    <option value={10}>10 per page</option>
                    <option value={25}>25 per page</option>
                    <option value={50}>50 per page</option>
                </PageSizeSelector>
            </ControlsContainer>

            {/* Use the new URLGrid to display URLCard components */}
            <URLGrid>
                {urlsWithStats.map(url => (
                    <URLCard key={url.id} url={url} />
                ))}
            </URLGrid>

            <PaginationContainer>
                <PageButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <FiChevronLeft />
                </PageButton>
                <span>Page {currentPage} of {totalPages}</span>
                <PageButton
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <FiChevronRight />
                </PageButton>
            </PaginationContainer>
        </AnalyticsContainer>
    );
};

export default Analytics;
import React from 'react';
import styled from 'styled-components';
import { FiExternalLink, FiBarChart2, FiCalendar, FiClock } from 'react-icons/fi';

const Card = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const URLTitle = styled.h3`
  font-size: 1rem;
  color: #2d3748;
  margin: 0;
  display: flex;
  align-items: center;
`;

const ExternalLink = styled.a`
  color: #4299e1;
  margin-left: 0.5rem;
  &:hover {
    color: #3182ce;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background: ${props => props.background || '#f7fafc'};
`;

const StatValue = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${props => props.color || '#2d3748'};
`;

const StatLabel = styled.span`
  font-size: 0.75rem;
  color: #718096;
  margin-top: 0.25rem;
`;

const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(num);
};

const URLCard = ({ url }) => {
    return (
      <Card>
        <CardHeader>
          <URLTitle>
            {url.short_url}
            <ExternalLink
              href={`${process.env.REACT_APP_API_BASE_URL}/${url.short_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiExternalLink size={14} />
            </ExternalLink>
          </URLTitle>
        </CardHeader>
        <StatsContainer>
          <StatItem background="#ebf8ff">
            <FiBarChart2 color="#4299e1" size={16} />
            <StatValue color="#4299e1">
              {formatNumber(url.total_hits)}
            </StatValue>
            <StatLabel>Total</StatLabel>
          </StatItem>
          <StatItem background="#f0fff4">
            <FiCalendar color="#48bb78" size={16} />
            <StatValue color="#48bb78">
              {formatNumber(url.weekly_hits)}
            </StatValue>
            <StatLabel>Weekly</StatLabel>
          </StatItem>
          <StatItem background="#fffaf0">
            <FiClock color="#ed8936" size={16} />
            <StatValue color="#ed8936">
              {formatNumber(url.today_hits)}
            </StatValue>
            <StatLabel>Today</StatLabel>
          </StatItem>
        </StatsContainer>
      </Card>
    );
};

export default URLCard;